<template>
    <b-container fluid>
        <iq-card>
           <template v-slot:headerTitle>
              <h4 class="card-title">{{ $t('tpm_report.trainer_performance_report') }} {{ $t('globalTrans.search') }}</h4>
            </template>
            <template v-slot:body>
              <b-row>
                <b-col lg="12" sm="12">
                  <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                    <b-form @submit.prevent="handleSubmit(searchData)" @reset.prevent="reset" >
                      <b-row>
                        <b-col lg="6" sm="6">
                          <ValidationProvider name="Fiscal Year"  vid="fiscal_year_id" rules="required|min_value:1">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="fiscal_year_id"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('elearning_config.fiscal_year')}} <span class="text-danger">*</span>
                              </template>
                              <b-form-select
                                  plain
                                  v-model="search.fiscal_year_id"
                                  :options="fiscalYearList"
                                  id="fiscal_year_id"
                                  :state="errors[0] ? false : (valid ? true : null)"
                              >
                                <template v-slot:first>
                                  <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                              </b-form-select>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="12" v-if="$store.state.Auth.activeRoleId === 1" >
                          <ValidationProvider name="Organization"  vid="org_id" rules="required|min_value:1">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="org_id"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('elearning_config.organization')}} <span class="text-danger">*</span>
                              </template>
                              <b-form-select
                                plain
                                v-model="search.org_id"
                                :options="orgList"
                                id="org_id"
                                :state="errors[0] ? false : (valid ? true : null)"
                              >
                                <template v-slot:first>
                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                                </b-form-select>
                                <div class="invalid-feedback">
                                  {{ errors[0] }}
                                </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="6">
                        <ValidationProvider name="Training Type"  vid="training_type_id" rules="required|min_value:1">
                          <b-form-group
                              class="row"
                              label-cols-sm="4"
                              label-for="training_type_id"
                              slot-scope="{ valid, errors }"
                          >
                            <template v-slot:label>
                              {{$t('elearning_config.training_type')}} <span class="text-danger">*</span>
                            </template>
                            <b-form-select
                              plain
                              v-model="search.training_type_id"
                              :options="trainingTypeList"
                              id="training_type_id"
                              :state="errors[0] ? false : (valid ? true : null)"
                              >
                              <template v-slot:first>
                                  <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                              </template>
                              </b-form-select>
                              <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="6">
                          <ValidationProvider name="Training Category" vid="training_category_id" rules="required|min_value:1">
                            <b-form-group
                              class="row"
                              label-cols-sm="4"
                              label-for="training_category_id"
                              slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('elearning_config.training_category')}} <span class="text-danger">*</span>
                              </template>
                              <b-form-select
                                plain
                                v-model="search.training_category_id"
                                :options="trainingCategoryList"
                                id="training_category_id"
                                :state="errors[0] ? false : (valid ? true : null)"
                                >
                                <template v-slot:first>
                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                                </b-form-select>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="6">
                          <ValidationProvider name="Training Title" vid="training_title_id" rules="required|min_value:1">
                            <b-form-group
                              class="row"
                              label-cols-sm="4"
                              label-for="training_title_id"
                              slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('elearning_config.training_title')}} <span class="text-danger">*</span>
                              </template>
                              <b-form-select
                                plain
                                v-model="search.training_title_id"
                                :options="trainingTitleList"
                                id="training_title_id"
                                :state="errors[0] ? false : (valid ? true : null)"
                                >
                                <template v-slot:first>
                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                                </b-form-select>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="6">
                          <ValidationProvider name="Batch No" vid="batch_no" rules="required|min_value:1">
                            <b-form-group
                              class="row"
                              label-cols-sm="4"
                              label-for="batch_no"
                              slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('elearning_iabm.batch_no')}} <span class="text-danger">*</span>
                              </template>
                              <b-form-select
                                plain
                                v-model="search.batch_no"
                                :options="batchList"
                                id="batch_no"
                                :state="errors[0] ? false : (valid ? true : null)"
                                >
                                <template v-slot:first>
                                    <b-form-select-option :value="0">{{ circularLoading ? 'Loading....' : $t('globalTrans.select')}}</b-form-select-option>
                                </template>
                                </b-form-select>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="6">
                          <ValidationProvider name="Circular Memo No" vid="circular_memo_no"  rules="required">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="circular_memo_no"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('elearning_iabm.circular_memo_no')}} <span class="text-danger">*</span>
                              </template>
                              <b-form-select
                                  plain
                                  disabled
                                  v-model="search.circular_memo_no"
                                  :options="circularList"
                                  id="circular_memo_no"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                >
                                <template v-slot:first>
                                  <b-form-select-option value=''>{{ circularLoading ? 'Loading....' : $t('globalTrans.select') }}</b-form-select-option>
                                </template>
                              </b-form-select>
                              <div class="invalid-feedback d-block">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="6">
                          <ValidationProvider name="Trainer"  vid="personal_info_id"  rules="required|min_value:1">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="personal_info_id"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{ $t('externalTraining.trainer_name') }} <span class="text-danger">*</span>
                              </template>
                              <b-form-select
                                  plain
                                  v-model="search.personal_info_id"
                                  :options="trainerList"
                                  id="personal_info_id"
                                  :state="errors[0] ? false : (valid ? true : null)"
                              >
                                <template v-slot:first>
                                  <b-form-select-option :value="0">{{ isLoading ? $t('globalTrans.loading') + '....' : $t('globalTrans.select') }}</b-form-select-option>
                                </template>
                              </b-form-select>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col cols="12">
                          <b-button type="submit" variant="primary" class="float-right">{{ $t('globalTrans.search') }}</b-button>
                        </b-col>
                      </b-row>
                    </b-form>
                  </ValidationObserver>
                </b-col>
              </b-row>
            </template>
        </iq-card>
        <b-row>
          <b-col md="12" v-if="showData">
            <iq-card>
              <template v-slot:headerTitle>
                <h4 class="card-title">{{ $t('tpm_report.trainer_performance_report') }}</h4>
              </template>
              <template v-slot:headerAction>
                <b-button class="btn_add_new" @click="pdfExport">
                  <i class="fas fa-print"></i>{{ $t('globalTrans.print') }}
                </b-button>
              </template>
              <template v-slot:body>
                <b-overlay :show="loading">
                  <b-row mt-5 >
                    <b-col md="12" class="table-responsive" v-if="datas.length>0">
                      <div style="border: 2px solid;margin:10px;padding:10px">
                        <b-row>
                          <b-col>
                            <list-report-head :base-url="trainingElearningServiceBaseUrl" uri="/config/report-head/detail" :org-id="12">
                              <template v-slot:projectNameSlot>
                                {{ }}
                              </template>
                              {{ $t('tpm_report.trainer_performance_report') }}
                            </list-report-head>
                          </b-col>
                        </b-row>
                        <b-row>
                          <b-col>
                            <div class="text-center">
                              <table style="width:100%;color:black;">
                                <tr>
                                  <td align="right" style="width:18%">{{ $t('elearning_config.fiscal_year') }}</td>
                                  <td align="center" style="width:2%">:</td>
                                  <td align="left" style="width:30%">{{ ($i18n.locale==='bn') ? search.fiscal_year_bn : search.fiscal_year }}</td>
                                  <td align="right" style="width:18%">{{ $t('elearning_config.organization') }}</td>
                                  <td align="center" style="width:2%">:</td>
                                  <td align="left" style="width:30%">{{ ($i18n.locale==='bn') ? search.org_name_bn : search.org_name }}</td>
                                </tr>
                                <tr>
                                  <td align="right" style="width:18%">{{ $t('elearning_config.training_type') }}</td>
                                  <td align="center" style="width:2%">:</td>
                                  <td align="left" style="width:30%">{{ ($i18n.locale==='bn') ? search.training_type_bn : search.training_type }}</td>
                                  <td align="right" style="width:18%">{{ $t('elearning_config.training_category') }}</td>
                                  <td align="center" style="width:2%">:</td>
                                  <td align="left" style="width:30%">{{ ($i18n.locale==='bn') ? search.training_category_bn : search.training_category }}</td>
                                </tr>
                                <tr>
                                  <td align="right" style="width:18%">{{ $t('elearning_config.training_title') }}</td>
                                  <td align="center" style="width:2%">:</td>
                                  <td align="left" style="width:30%">{{ ($i18n.locale==='bn') ? search.training_title_bn : search.training_title }}</td>
                                  <!-- <td align="right" style="width:18%">{{ $t('externalTraining.trainer_name') }}</td>
                                  <td align="center" style="width:2%">:</td>
                                  <td align="left" style="width:30%">{{ ($i18n.locale==='bn') ? datas[0].name_bn : datas[0].name }}</td> -->
                                  <td align="right" style="width:18%">{{ $t('elearning_iabm.circular_memo_no') }}</td>
                                  <td align="center" style="width:2%">:</td>
                                  <td align="left" style="width:30%">{{  search.circular_memo_no }}</td>
                                </tr>
                                <tr>
                                  <td align="right" style="width:18%">{{ $t('elearning_iabm.batch_no') }}</td>
                                  <td align="center" style="width:2%">:</td>
                                  <td align="left" style="width:30%">{{ ($i18n.locale==='bn') ? search.batch_name_bn : search.batch_name }}</td>
                                </tr>
                              </table>
                            </div>
                          </b-col>
                        </b-row>
                        <b-row>
                          <div class="col-md-12" v-for="(item,index) in datas" :key="index">
                            <b-table-simple class="evaluation-table" borderless style="width:100%">
                              <b-tr>
                                <b-td><div class="pt-0"><span style="font-weight: bold;">{{$t('elearning_tpm.course_name')}}</span> : {{ $i18n.locale === 'bn' ? item.topics_session_bn : item.topics_session }}</div></b-td>
                                <b-td><div class="pb-0"><span style="font-weight: bold;">{{ $t('elearning_tim.evaluation') }}</span> : {{ $n(item.avg_mark) }}</div></b-td>
                              </b-tr>
                              <b-tr>
                                <b-td><div class="pb-0"><span style="font-weight: bold;">{{ $t('elearning_tim.trainer_name') }}</span> : {{ $i18n.locale === 'bn' ? item.name_bn : item.name }}</div></b-td>
                                <b-td><div class="pb-0"><span style="font-weight: bold;">{{ $t('globalTrans.average') }}</span> : {{ $n(avgCalculation(item.avg_mark)) }}</div></b-td>
                              </b-tr>
                            </b-table-simple>
                            <hr style="margin-top: 5px;margin-bottom: 10px;"/>
                            <b-table-simple class="evaluation-table" bordered style="width:100%">
                              <b-tr>
                                <b-th style="width:70%">{{ $t('tpm_report.evaluation_name') }}</b-th>
                                <b-th style="width:30%">{{ $t('tpm_report.evaluation_mark') }}</b-th>
                              </b-tr>
                              <b-tr v-for="(itm,index1) in item.evalution" :key="index1">
                                <b-td class="text-left pl-3">
                                  <span>{{$n(index1+1)}}{{ '.' }} {{ $i18n.locale === 'bn' ? itm.trainer_evaluation_name_bn : itm.trainer_evaluation_name }}</span>
                                </b-td>
                                <b-td class="text-left pl-3">
                                  {{ $n(itm.avg_mark) }}
                                </b-td>
                              </b-tr>
                            </b-table-simple>
                            <!-- <div class="d-flex" id="eval" v-for="(itm,index1) in item.evalution" :key="index1">
                              <div class="col-sm-6 pb-0">
                                <span>{{$n(index1+1)}}{{ '.' }} {{ $i18n.locale === 'bn' ? itm.trainer_evaluation_name_bn : itm.trainer_evaluation_name }}</span>
                              </div>
                              <div class="col-sm-6 pb-0">
                                <b-table-simple class="evaluation-table" bordered style="width:100%">
                                  <b-tr class="text-center">
                                    <b-td style="width: 60px;">
                                      <span v-if="itm.avg_mark >= 1 && itm.avg_mark < 2"><i class="text-success fa fontawesome">&#xf00c;</i></span>
                                      <span v-else>{{ $i18n.locale === 'bn' ? '১' : '1' }}</span>
                                    </b-td>
                                    <b-td style="width: 60px;">
                                      <span v-if="itm.avg_mark >= 2 && itm.avg_mark < 3"><i class="text-success fa fontawesome">&#xf00c;</i></span>
                                      <span v-else>{{ $i18n.locale === 'bn' ? '২' : '2' }}</span>
                                    </b-td>
                                    <b-td style="width: 60px;">
                                      <span v-if="itm.avg_mark >= 3 && itm.avg_mark < 4"><i class="text-success fa fontawesome">&#xf00c;</i></span>
                                      <span v-else>{{ $i18n.locale === 'bn' ? '৩' : '3' }}</span>
                                    </b-td>
                                    <b-td style="width: 60px;">
                                      <span v-if="itm.avg_mark >= 4 && itm.avg_mark < 5"><i class="text-success fa fontawesome">&#xf00c;</i></span>
                                      <span v-else>{{ $i18n.locale === 'bn' ? '৪' : '4' }}</span>
                                    </b-td>
                                    <b-td style="width: 60px;">
                                      <span v-if="itm.avg_mark === 5"><i class="text-success fa fontawesome">&#xf00c;</i></span>
                                      <span v-else>{{ $i18n.locale === 'bn' ? '৫' : '5' }}</span>
                                    </b-td>
                                  </b-tr>
                                </b-table-simple>
                            </div>
                            </div> -->
                          </div>
                        </b-row>
                        <b-row>
                          <b-col class="text-center" v-if="$i18n.locale==='bn' && datas.length > 0"><strong>{{ $t('tpm_report.idsdp') }}</strong> {{ $t('tpm_report.report_footer1') }} {{ $t('tpm_report.report_footer2') }}</b-col>
                          <b-col class="text-center" v-if="$i18n.locale==='en' && datas.length > 0">{{ $t('tpm_report.report_footer1') }} <strong>{{ $t('tpm_report.idsdp') }}</strong> {{ $t('tpm_report.report_footer2') }}</b-col>
                        </b-row>
                      </div>
                    </b-col>
                  </b-row>
                </b-overlay>
              </template>
            </iq-card>
          </b-col>
        </b-row>
    </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { trainingElearningServiceBaseUrl } from '@/config/api_config'
import { trainerPerformanceReport, circularList } from '../../api/routes'
import flatpickr from 'flatpickr'
import ListReportHead from '@/components/custom/ListReportHeadTraining.vue'
import ExportPdf from './export_pdf_details'
import excel from 'vue-excel-export'
import Vue from 'vue'
import Store from '@/store'
Vue.use(excel)

export default {
    props: ['id'],
    components: {
        ValidationObserver,
        ValidationProvider,
        ListReportHead
    },
    created () {
        this.search = Object.assign({}, this.search, {
            org_id: this.$store.state.dataFilters.orgId,
            fiscal_year_id: this.$store.state.TrainingElearning.currentFiscalYearId
        })
    },
    data () {
        return {
          circularLoading: false,
          search: {
            org_id: 0,
            training_type_id: 0,
            training_category_id: 0,
            training_title_id: 0,
            fiscal_year_id: 0,
            batch_no: 0,
            circular_memo_no: '',
            personal_info_id: 0
          },
          allBatchListData: [],
          batchList: [],
          circularList: [],
          trainingTypeList: [],
          trainingCategoryList: [],
          trainingTitleList: [],
          trainingElearningServiceBaseUrl: trainingElearningServiceBaseUrl,
          datas: [],
          circularInfo: [],
          trainingStartDate: '',
          trainingEndDate: '',
          disciplinaryAction: {
            disciplinary_action: '',
            disciplinary_action_bn: ''
          },
          showData: false,
          grading_total: '',
          grading_total_bn: '',
          trainerList: [],
          isLoading: false
        }
    },
    mounted () {
        core.index()
        flatpickr('.fromDate', {})
    },
    computed: {
      orgList: function () {
        return this.$store.state.commonObj.organizationProfileList.filter(item => item.status === 0)
      },
      fiscalYearList: function () {
        return this.$store.state.commonObj.fiscalYearList.filter(item => item.status === 0)
      },
      trainerEvaluationList: function () {
        return this.$store.state.TrainingElearning.commonObj.trainerEvaluationList.filter(item => item.status === 1)
      },
      loading () {
        return this.$store.state.commonObj.loading
      },
      allBatchList () {
        return [
          { value: 1, text: this.$i18n.locale === 'en' ? '1st' : '১ম', text_en: '1st', text_bn: '১ম' },
          { value: 2, text: this.$i18n.locale === 'en' ? '2nd' : '২য়', text_en: '2nd', text_bn: '২য়' },
          { value: 3, text: this.$i18n.locale === 'en' ? '3rd' : '৩য়', text_en: '3rd', text_bn: '৩য়' },
          { value: 4, text: this.$i18n.locale === 'en' ? '4th' : '৪র্থ', text_en: '4th', text_bn: '৪র্থ' },
          { value: 5, text: this.$i18n.locale === 'en' ? '5th' : '৫ম', text_en: '5th', text_bn: '৫ম' },
          { value: 6, text: this.$i18n.locale === 'en' ? '6th' : '৬ষ্ঠ', text_en: '6th', text_bn: '৬ষ্ঠ' },
          { value: 7, text: this.$i18n.locale === 'en' ? '7th' : '৭ম', text_en: '7th', text_bn: '৭ম' },
          { value: 8, text: this.$i18n.locale === 'en' ? '8th' : '৮ম', text_en: '8th', text_bn: '৮য়' },
          { value: 9, text: this.$i18n.locale === 'en' ? '9th' : '৯ম', text_en: '9th', text_bn: '৯ম' },
          { value: 10, text: this.$i18n.locale === 'en' ? '10th' : '১০ম', text_en: '10th', text_bn: '১০ম' }
        ]
      },
      monthList: function () {
        return [
          {
            value: 1,
            text: this.$i18n.locale === 'bn' ? 'জানুয়ারি' : 'January',
            text_en: 'January',
            text_bn: 'জানুয়ারি'
          },
          {
            value: 2,
            text: this.$i18n.locale === 'bn' ? 'ফেব্রুয়ারি' : 'February',
            text_en: 'February',
            text_bn: 'ফেব্রুয়ারি'
          },
          {
            value: 3,
            text: this.$i18n.locale === 'bn' ? 'মার্চ' : 'March',
            text_en: 'March',
            text_bn: 'মার্চ'
          },
          {
            value: 4,
            text: this.$i18n.locale === 'bn' ? 'এপ্রিল' : 'April',
            text_en: 'April',
            text_bn: 'এপ্রিল'
          },
          {
            value: 5,
            text: this.$i18n.locale === 'bn' ? 'মে' : 'May',
            text_en: 'May',
            text_bn: 'মে'
          },
          {
            value: 6,
            text: this.$i18n.locale === 'bn' ? 'জুন' : 'June',
            text_en: 'June',
            text_bn: 'জুন'
          },
          {
            value: 7,
            text: this.$i18n.locale === 'bn' ? 'জুলাই' : 'July',
            text_en: 'July',
            text_bn: 'জুলাই'
          },
          {
            value: 8,
            text: this.$i18n.locale === 'bn' ? 'আগস্ট' : 'August',
            text_en: 'August',
            text_bn: 'আগস্ট'
          },
          {
            value: 9,
            text: this.$i18n.locale === 'bn' ? 'সেপ্টেম্বর' : 'September',
            text_en: 'September',
            text_bn: 'সেপ্টেম্বর'
          },
          {
            value: 10,
            text: this.$i18n.locale === 'bn' ? 'অক্টোবর' : 'October',
            text_en: 'October',
            text_bn: 'অক্টোবর'
          },
          {
            value: 11,
            text: this.$i18n.locale === 'bn' ? 'নভেম্বর' : 'November',
            text_en: 'November',
            text_bn: 'নভেম্বর'
          },
          {
            value: 12,
            text: this.$i18n.locale === 'bn' ? 'ডিসেম্বর' : 'December',
            text_en: 'December',
            text_bn: 'ডিসেম্বর'
          }
        ]
      }
    },
    watch: {
      'search.org_id': function (newValue) {
        this.trainingTypeList = this.getTypeList(newValue)
      },
      'search.training_type_id': function (newValue) {
        this.trainingCategoryList = this.getCategoryList(newValue)
      },
      'search.training_category_id': function (newValue) {
        this.trainingTitleList = this.getTrainingTitleList(newValue)
      },
      // 'search.training_title_id': function (newValue) {
      //   this.getTrainerList(newValue)
      // },
      'search.training_title_id': function (newVal, oldVal) {
        if (newVal !== oldVal) {
          this.getCircularList()
        }
      },
      'search.fiscal_year_id': function (newValue) {
        this.getCircularList()
      },
      'search.circular_memo_no': function (newVal, oldVal) {
        if (newVal !== oldVal) {
          const allBatchListData = this.allBatchListData.find(allBatchListData => String(allBatchListData.circular_memo_no) === String(newVal))
          if (typeof allBatchListData !== 'undefined') {
            this.search.batch_no = allBatchListData.batch_no
          }
          this.getTrainerList()
        }
      },
      'search.batch_no': function (newVal, oldVal) {
        if (newVal !== oldVal) {
          const allBatchListData = this.allBatchListData.find(allBatchListData => parseInt(allBatchListData.batch_no) === parseInt(newVal))
          if (typeof allBatchListData !== 'undefined') {
            this.search.circular_memo_no = allBatchListData.circular_memo_no
          }
        }
      }
    },
    methods: {
      avgCalculation (data) {
        return parseFloat(data * 100 / 5).toFixed(2)
      },
      batchInfo () {
        const batchData = this.allBatchListData.filter(allBatchList => parseInt(allBatchList.training_title_id) === parseInt(this.search.training_title_id))
        const batchList = []
        batchData.map((obj, index) => {
          if (obj.batch_no) {
            const isThere = this.allBatchList.find(allBatchList => parseInt(allBatchList.value) === parseInt(obj.batch_no))
            if (typeof isThere !== 'undefined') {
              batchList.push(isThere)
            }
          }
        })
        this.batchList = batchList
        if (this.batchList.length === 1) {
          this.search.batch_no = 1
        }
      },
      circluarList () {
        const listObject = this.allBatchListData.filter(allBatchListData => parseInt(allBatchListData.training_title_id) === parseInt(this.search.training_title_id))
        const tmpList = listObject.map((obj, index) => {
          if (this.$i18n.locale === 'bn') {
            return { value: obj.circular_memo_no, text: obj.circular_memo_no, batch_no: obj.batch_no }
          } else {
            return { value: obj.circular_memo_no, text: obj.circular_memo_no, batch_no: obj.batch_no }
          }
        })
        this.circularList = tmpList
      },
      async getCircularList () {
        this.circularLoading = true
        const serchData = {
          fiscal_year_id: this.search.fiscal_year_id,
          training_type_id: this.search.training_type_id,
          training_category_id: this.search.training_category_id,
          training_title_id: this.search.training_title_id
        }
        const result = await RestApi.getData(trainingElearningServiceBaseUrl, circularList + '/' + 'iab_circular_publications', serchData)
        if (!result.success) {
        } else {
          this.allBatchListData = result.batchList
          this.batchInfo()
          this.circluarList()
        }
        this.circularLoading = false
      },
      getTypeList (orgId) {
        const type = this.$store.state.TrainingElearning.commonObj.trainingTypeList.filter(item => item.status === 1)
        if (orgId) {
          return type.filter(item => item.org_id === parseInt(orgId))
        }
        return type
      },
      getCategoryList (typeId) {
        const trainingCategoryList = this.$store.state.TrainingElearning.commonObj.trainingCategoryList.filter(item => item.status === 1)
        if (typeId) {
          return trainingCategoryList.filter(item => item.training_type_id === typeId)
        }
        return trainingCategoryList
      },
      getTrainingTitleList (categoryId) {
        const trainingTitleList = this.$store.state.TrainingElearning.commonObj.trainingTitleList.filter(item => item.status === 1)
        if (categoryId) {
          return trainingTitleList.filter(item => item.training_category_id === categoryId)
        }
        return trainingTitleList
      },
      async getTrainerList () {
        this.isLoading = true
        const serchData = {
          circular_memo_no: this.search.circular_memo_no
        }
        const result = await RestApi.getData(trainingElearningServiceBaseUrl, '/iabm/training-applications/trainer-list', serchData)
        if (result.success) {
          this.trainerList = result.data.map(item => {
            return { value: item.value, text: this.$i18n.locale === 'en' ? item.text_en : item.text_bn }
          })
        } else {
          this.trainerList = []
          this.isLoading = false
        }
        this.isLoading = false
      },
      pdfExport () {
          const reportTitle = this.$t('tpm_report.trainer_performance_report')
          ExportPdf.exportPdfDetails(trainingElearningServiceBaseUrl, '/config/report-head/detail', 12, reportTitle, this.datas, this, this.search)
      },
      async searchData () {
        Store.commit('mutateCommonProperties', {
          loading: true
        })
        this.showData = true
        const orgObj = this.$store.state.commonObj.organizationProfileList.find(item => item.value === this.search.org_id)
        this.search.org_name = typeof orgObj !== 'undefined' ? orgObj.text_en : ''
        this.search.org_name_bn = typeof orgObj !== 'undefined' ? orgObj.text_bn : ''

        const fiscalYearObj = this.$store.state.commonObj.fiscalYearList.find(item => item.value === this.search.fiscal_year_id)
        this.search.fiscal_year = typeof fiscalYearObj !== 'undefined' ? fiscalYearObj.text_en : ''
        this.search.fiscal_year_bn = typeof fiscalYearObj !== 'undefined' ? fiscalYearObj.text_bn : ''

        const trainingTypeObj = this.$store.state.TrainingElearning.commonObj.trainingTypeList.find(item => item.value === this.search.training_type_id)
        this.search.training_type = typeof trainingTypeObj !== 'undefined' ? trainingTypeObj.text_en : ''
        this.search.training_type_bn = typeof trainingTypeObj !== 'undefined' ? trainingTypeObj.text_bn : ''

        const trainingCategoryListObj = this.$store.state.TrainingElearning.commonObj.trainingCategoryList.find(item => item.value === this.search.training_category_id)
        this.search.training_category = typeof trainingCategoryListObj !== 'undefined' ? trainingCategoryListObj.text_en : ''
        this.search.training_category_bn = typeof trainingCategoryListObj !== 'undefined' ? trainingCategoryListObj.text_bn : ''

        const trainingTitleListObj = this.$store.state.TrainingElearning.commonObj.trainingTitleList.find(item => item.value === this.search.training_title_id)
        this.search.training_title = typeof trainingTitleListObj !== 'undefined' ? trainingTitleListObj.text_en : ''
        this.search.training_title_bn = typeof trainingTitleListObj !== 'undefined' ? trainingTitleListObj.text_bn : ''

        const batchObj = this.allBatchList.find(item => item.value === this.search.batch_no)
        this.search.batch_name = typeof batchObj !== 'undefined' ? batchObj.text_en : ''
        this.search.batch_name_bn = typeof batchObj !== 'undefined' ? batchObj.text_bn : ''

        const personalInfoObj = this.trainerList.find(item => item.value === this.search.personal_info_id)
        this.search.trainer_name = typeof personalInfoObj !== 'undefined' ? personalInfoObj.text_en : ''
        this.search.trainer_name_bn = typeof personalInfoObj !== 'undefined' ? personalInfoObj.text_bn : ''

        const result = await RestApi.getData(trainingElearningServiceBaseUrl, trainerPerformanceReport, this.search)
        if (result.success) {
          Store.commit('mutateCommonProperties', {
            loading: false
          })
          if (result.data) {
            // this.datas = this.getCustomDataList(result.data)
            this.datas = result.data
            this.circularInfo = result.circularInfo
            const startDate = this.circularInfo.training_start_date.split('-')
            const endDate = this.circularInfo.training_end_date.split('-')
            const sMonth = startDate[1]
            const eMonth = endDate[1]
            const sMonthName = this.monthList.find(doc => doc.value === parseInt(sMonth))
            const eMonthName = this.monthList.find(doc => doc.value === parseInt(eMonth))
            if (parseInt(startDate[2]) < 10) {
              this.trainingStartDate = this.$n(0) + this.$n(startDate[2], { useGrouping: false }) + ' ' + (this.currentLocale === 'en' ? sMonthName.text_en : sMonthName.text_bn) + ' ' + this.$n(startDate[0], { useGrouping: false })
            } else {
              this.trainingStartDate = this.$n(startDate[2], { useGrouping: false }) + ' ' + (this.currentLocale === 'en' ? sMonthName.text_en : sMonthName.text_bn) + ' ' + this.$n(startDate[0], { useGrouping: false })
            }
            if (parseInt(endDate[2]) < 10) {
              this.trainingEndDate = this.$n(0) + this.$n(endDate[2], { useGrouping: false }) + ' ' + (this.currentLocale === 'en' ? eMonthName.text_en : eMonthName.text_bn) + ' ' + this.$n(endDate[0], { useGrouping: false })
            } else {
              this.trainingEndDate = this.$n(endDate[2], { useGrouping: false }) + ' ' + (this.currentLocale === 'en' ? eMonthName.text_en : eMonthName.text_bn) + ' ' + this.$n(endDate[0], { useGrouping: false })
            }
          }
        } else {
          this.datas = []
          this.circularInfo = []
        }
      },
      getCustomDataList (data) {
        const listData = data.map(item => {
          const gradingStatus = this.$store.state.TrainingElearning.commonObj.gradingStatus
          const customItem = {
            grading: null,
            grading_bn: null
          }
          gradingStatus.map(grading => {
            if (parseFloat(grading.below_number) <= parseFloat(item.attain_marks) && parseFloat(grading.above_number) >= parseFloat(item.attain_marks)) {
              customItem.grading = grading.text_en
              customItem.grading_bn = grading.text_en
            }
          })
          return Object.assign({}, item, customItem)
        })
        return listData
      }
    }
}
</script>
